import React, { useContext } from 'react'
import { GlobalContext } from '../../data/GlobalContext';
import { Row, Col } from 'react-bootstrap'
import line1 from '../../../assets/img/lines/12.png'
import line2 from '../../../assets/img/lines/11.png'


const imgUrl = require.context('../../../assets/img', true);

function SocialMediaBlock(props) {
    const dbData = useContext(GlobalContext);
    return (
        <section className="service-section shape-style-two service-line-shape section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                {
                    dbData.gmb.link !==' ' ?
                    <div className='service-boxes d-flex justify-content-center'>
                        <a href={dbData?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                        <img src={dbData?.gmb?.img} alt="Not found" width={'250px'} />
                        </a>
                    </div>
                    : null

                }
                <div className="section-title text-center both-border mb-50">
                    <h2 className="title text-capitalize">follow us</h2>
                </div>
                {/* Services Boxes */}
                <Row className="service-boxes justify-content-center">
                    {
                        dbData?.dbSocialMedia?.directorios.length > 0 ?
                        dbData?.dbSocialMedia?.directorios.map((item, index) => {
                          return (
                            <div
                              className="text-center content-directorios"
                              key={index}
                            >
                              <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                <img src={item.image} alt="Not found" width={'250px'} />
                              </a>
                            </div>
                          );
                        }) : null
                    }
                    {
                        dbData?.dbSocialMedia?.redes.length > 0 ?
                        dbData?.dbSocialMedia?.redes.map((item, index) => {
                          return (
                            <div
                              className="text-center content-directorios"
                              key={index}
                            >
                              <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                <img src={item.image} alt="Not Found" width={'250px'} />
                              </a>
                            </div>
                          );
                        }) : null
                    }
                </Row>
            </div>
            <div className="line-one">
                <img src={line1} alt="line-shape" />
            </div>
            <div className="line-two">
                <img src={line2} alt="line-shape" />
            </div>
        </section>
    );
}

export default SocialMediaBlock;